import React, { useState, useEffect } from 'react';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  collection,
  addDoc,
} from 'firebase/firestore';
import logo from './wizardlogo.png';
import './App.css'; // Ensure you're importing your CSS file

// Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDn6CKZ6u8X6E4nB87xOSYg5owfMmtS_M8',
  authDomain: 'dcf-wizard-main.firebaseapp.com',
  projectId: 'dcf-wizard-main',
  storageBucket: 'dcf-wizard-main.appspot.com',
  messagingSenderId: '514388252730',
  appId: '1:514388252730:web:dde0371d9666e2df1efd03',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const App = () => {
  const [ticker, setTicker] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [daysSinceSignup, setDaysSinceSignup] = useState(null);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [showBankModelPrompt, setShowBankModelPrompt] = useState(false);
  const [calculationCount, setCalculationCount] = useState(0);
  const [calculationLimitReached, setCalculationLimitReached] = useState(false);

  // Maximum allowed calculations
  const MAX_CALCULATIONS_NON_SIGNED_IN = 3;
  const MAX_CALCULATIONS_SIGNED_IN = 10;

  // Google sign-in function
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const loggedInUser = result.user;
      const userDoc = doc(db, 'users', loggedInUser.uid);
      const docSnap = await getDoc(userDoc);

      if (!docSnap.exists()) {
        await setDoc(userDoc, {
          displayName: loggedInUser.displayName,
          email: loggedInUser.email,
          createdAt: new Date(),
        });
      }

      setUser(loggedInUser);
      calculateDaysSinceSignup(new Date());
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  const signOutUser = async () => {
    await signOut(auth);
    setUser(null);
    setIsSidebarOpen(false);
  };

  const calculateDaysSinceSignup = (createdAt) => {
    if (createdAt && createdAt.seconds) {
      const currentDate = new Date();
      const signupDate = new Date(createdAt.seconds * 1000); // Convert Firestore Timestamp to JS Date
      const diffTime = Math.abs(currentDate - signupDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setDaysSinceSignup(diffDays);
    } else {
      setDaysSinceSignup('...');
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDoc = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(userDoc);
        if (docSnap.exists()) {
          const { createdAt } = docSnap.data();
          if (createdAt) {
            calculateDaysSinceSignup(createdAt);
          } else {
            setDaysSinceSignup('...');
          }
        }
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const closeSidebarOnClickOutside = (e) => {
    const sidebar = document.getElementById('sidebar');
    if (sidebar && !sidebar.contains(e.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener('mousedown', closeSidebarOnClickOutside);
    } else {
      document.removeEventListener('mousedown', closeSidebarOnClickOutside);
    }
    return () =>
      document.removeEventListener('mousedown', closeSidebarOnClickOutside);
  }, [isSidebarOpen]);

  // Fetch data from backend
  const fetchData = async (ticker, useBankModel = false) => {
    setLoading(true);
    setError(null);
    setResult(null);
    setFeedbackSubmitted(false); 
    setFeedback(null);
    setShowBankModelPrompt(false); 

    try {
      const response = await fetch(
        `https://dcf-wizard-backend-514388252730.us-central1.run.app/api/calculate-dcf?ticker=${ticker.toUpperCase()}&userId=${user ? user.uid : ''}&useBankModel=${useBankModel}`
      );
      const data = await response.json();
      if (response.status === 403) {
        setCalculationLimitReached(true);
        return;
      }
      if (!response.ok) {
        throw new Error(data.error || 'Unknown error occurred');
      }

      setCalculationLimitReached(false);
      if (data.isBank && !useBankModel) {
        setShowBankModelPrompt(true);
        setResult(data); 
      } else {
        setResult(data);
      }

      // Increment the calculation count
      setCalculationCount((prevCount) => prevCount + 1);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ticker) {
      if (user) {
        if (calculationCount < MAX_CALCULATIONS_SIGNED_IN) {
          fetchData(ticker);
        } else {
          setCalculationLimitReached(true);
        }
      } else {
        if (calculationCount < MAX_CALCULATIONS_NON_SIGNED_IN) {
          fetchData(ticker);
        } else {
          setCalculationLimitReached(true);
        }
      }
    }
  };

  const handleFeedback = async (userFeedback) => {
    setFeedback(userFeedback);
    setFeedbackSubmitted(true);

    try {
      await addDoc(collection(db, 'feedback'), {
        ticker: result.ticker,
        feedback: userFeedback,
        intrinsicValuePerShare: result.intrinsicValuePerShare,
        userId: user ? user.uid : null,
        timestamp: new Date(),
      });
    } catch (error) {
      console.error('Error saving feedback:', error);
    }
  };

  const handleBankModelChoice = (useBankModel) => {
    setShowBankModelPrompt(false);
    fetchData(ticker, useBankModel);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        padding: '20px',
        boxSizing: 'border-box',
      }}
    >
      <h1
        style={{
          fontSize: '1.8rem',
          marginBottom: '20px',
          color: '#805AD5',
          fontFamily: "'Permanent Marker', cursive",
        }}
      >
        DCF Wizard
      </h1>

      <img
        src={logo}
        alt="DCF Wizard Logo"
        style={{ width: '150px', height: 'auto', marginBottom: '20px' }}
      />

      <form
        onSubmit={handleSubmit}
        style={{ width: '100%', maxWidth: '400px', textAlign: 'center' }}
      >
        <input
          type="text"
          value={ticker}
          onChange={(e) => setTicker(e.target.value)}
          placeholder="Enter ticker symbol"
          style={{
            padding: '10px',
            width: '90%',
            marginBottom: '20px',
            textAlign: 'center',
            fontFamily: "'Permanent Marker', cursive",
          }}
        />
        <button
          type="submit"
          style={{
            padding: '10px',
            width: '100%',
            backgroundColor: '#805AD5',
            color: 'white',
            fontFamily: "'Permanent Marker', cursive",
          }}
        >
          {loading ? 'Calculating...' : 'Calculate'}
        </button>
      </form>

      {user ? (
        <button
          onClick={() => setIsSidebarOpen(true)}
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            backgroundColor: '#805AD5',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '5px',
          }}
        >
          {user.displayName}
        </button>
      ) : (
        <button
          onClick={signInWithGoogle}
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            backgroundColor: '#805AD5',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '5px',
          }}
        >
          Sign in with Google
        </button>
      )}

      {isSidebarOpen && (
        <div
          id="sidebar"
          style={{
            position: 'fixed',
            right: 0,
            top: 0,
            width: '300px',
            height: '100vh',
            backgroundColor: '#2D3748',
            color: 'white',
            padding: '20px',
            boxShadow: '-2px 0px 10px rgba(0,0,0,0.5)',
          }}
        >
          <button
            onClick={() => setIsSidebarOpen(false)}
            style={{
              backgroundColor: 'transparent',
              color: 'white',
              border: 'none',
              float: 'right',
              fontSize: '20px',
              cursor: 'pointer',
            }}
          >
            X
          </button>
          <h2 style={{ marginTop: '20px', marginBottom: '10px' }}>
            {user.displayName}'s Profile
          </h2>
          <p>
            <strong>Name:</strong> {user.displayName}
          </p>
          <p>
            <strong>Email:</strong> {user.email}
          </p>
          <p>Valuation wizard for {daysSinceSignup || '...'} days!</p>
          <button
            onClick={signOutUser}
            style={{
              padding: '10px 20px',
              backgroundColor: '#E53E3E',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              marginTop: '20px',
            }}
          >
            Sign Out
          </button>
        </div>
      )}

      {calculationLimitReached && (
        <div style={{ color: 'red', marginTop: '20px' }}>
          <p>
            You have reached the maximum number of calculations. Please sign in
            or purchase a subscription for more.
          </p>
        </div>
      )}

      {error && <p style={{ color: 'red' }}>Error: {error}</p>}

      {showBankModelPrompt && (
        <div style={{ marginTop: '20px' }}>
          <p>
            The company you entered is a bank or financial institution. Would
            you like to use a bank-specific valuation model?
          </p>
          <button
            onClick={() => handleBankModelChoice(true)}
            style={{
              marginRight: '10px',
              padding: '10px 20px',
              backgroundColor: '#38A169',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Yes, use bank model
          </button>
          <button
            onClick={() => handleBankModelChoice(false)}
            style={{
              padding: '10px 20px',
              backgroundColor: '#E53E3E',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            No, use standard model
          </button>
        </div>
      )}

      {result && !showBankModelPrompt && (
        <div style={{ marginTop: '20px' }}>
          <h2>Results for {result.ticker}</h2>
          {result.modelUsed === 'DDM' ? (
            <>
              <p>
                <strong>Model Used:</strong> Dividend Discount Model (DDM)
              </p>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: '10px',
                  textAlign: 'left',
                }}
              >
                <p>
                  <strong>Dividend Growth Rate:</strong>{' '}
                  {(result.dividendGrowthRate * 100).toFixed(2)}%
                </p>
                <p>
                  <strong>Beta:</strong> {result.beta}
                </p>
                <p>
                  <strong>Cost of Equity:</strong>{' '}
                  {(result.costOfEquity * 100).toFixed(2)}%
                </p>
                <p>
                  <strong>Intrinsic Value per Share:</strong> $
                  {result.intrinsicValuePerShare.toFixed(2)}
                </p>
                <p>
                  <strong>Upside/Downside:</strong>{' '}
                  {result.upsideDownside.toFixed(2)}%
                </p>
              </div>
            </>
          ) : (
            <>
              <p>
                <strong>Model Used:</strong> Discounted Cash Flow (DCF)
              </p>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: '10px',
                  textAlign: 'left',
                }}
              >
                <p>
                  <strong>Free Cash Flow:</strong>{' '}
                  ${result.freeCashFlow.toLocaleString()}
                </p>
                <p>
                  <strong>FCF Growth Rate:</strong>{' '}
                  {(result.fcfGrowthRate * 100).toFixed(2)}%
                </p>
                <p>
                  <strong>Beta:</strong> {result.beta}
                </p>
                <p>
                  <strong>Cost of Equity:</strong>{' '}
                  {(result.costOfEquity * 100).toFixed(2)}%
                </p>
                <p>
                  <strong>Cost of Debt:</strong>{' '}
                  {(result.costOfDebt * 100).toFixed(2)}%
                </p>
                <p>
                  <strong>WACC:</strong> {(result.wacc * 100).toFixed(2)}%
                </p>
                <p>
                  <strong>Market Cap:</strong>{' '}
                  ${result.marketCap.toLocaleString()}
                </p>
                <p>
                  <strong>Total Debt:</strong>{' '}
                  ${result.totalDebt.toLocaleString()}
                </p>
                <p>
                  <strong>Stock Price:</strong> $
                  {result.stockPrice.toFixed(2)}
                </p>
                <p>
                  <strong>Intrinsic Value per Share:</strong> $
                  {result.intrinsicValuePerShare.toFixed(2)}
                </p>
                <p>
                  <strong>Upside/Downside:</strong>{' '}
                  {result.upsideDownside.toFixed(2)}%
                </p>
              </div>
            </>
          )}

          {!feedbackSubmitted ? (
            <div style={{ marginTop: '20px' }}>
              <p>Does this seem realistic?</p>
              <button
                onClick={() => handleFeedback('Yes')}
                className="feedback-button yes"
              >
                Yes
              </button>
              <button
                onClick={() => handleFeedback('No')}
                className="feedback-button no"
              >
                No
              </button>
            </div>
          ) : (
            <p>
              Thank you for your feedback! The intrinsic value per share was $
              {result.intrinsicValuePerShare.toFixed(2)}.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
